import React from "react";

import { LanguageProvider } from "../components/languageProvider";
import { OfferPage as Offer } from "../components/offerPage";

const OfferPage = ({ pageContext }) => {
  return (
    <LanguageProvider language="pl">
      <Offer pageContext={pageContext} />
    </LanguageProvider>
  );
};

export default OfferPage;
