import React from "react";

import { Layout } from "./layout";
import SEO from "./seo";
import { Row, Column } from "./grid";
import { Heading } from "./heading";
import { Divider } from "./divider";
import { OfferColumn, OfferList, OfferListItem, OfferCheckMark } from "./offer";
import { useScreenInfo } from "./useScreenInfo";

import { renderRichText } from "../richTextRenderer";

export function OfferPage({ pageContext }) {
  const { data } = pageContext;
  const { isLargeUp } = useScreenInfo();

  return (
    <Layout subPage>
      <SEO title={data.title} />

      <Row>
        <Column xs={12}>
          <Heading>{data.title}</Heading>
        </Column>
      </Row>

      <Row>
        <OfferColumn left={isLargeUp} xs={12} lg={data.offerItems ? 6 : 12}>
          {renderRichText(data.description.json)}
        </OfferColumn>

        {data.offerItems && (
          <OfferColumn right={isLargeUp} xs={12} lg={6}>
            {isLargeUp && <Divider />}
            <OfferList>
              {data.offerItems.map(({ description }) => {
                return (
                  <OfferListItem key={description.id}>
                    <OfferCheckMark />
                    {renderRichText(description.json)}
                  </OfferListItem>
                );
              })}
            </OfferList>
          </OfferColumn>
        )}
      </Row>
    </Layout>
  );
}
